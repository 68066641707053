<template>
  <div>
    <portal to="breadcrumb">
      <Breadcrumb>
        <b-breadcrumb-item active>
          Execution log
        </b-breadcrumb-item>
      </Breadcrumb>
    </portal>
    <portal to="secondNavbarLeft">
      <b-navbar-item tag="div">
        <div
          style="display: inline-flex; flex-wrap: wrap; gap:5px 5px;"
        >
          <searchable-dropdown
            :dropdown-properties="filters.ACTION.value"
            :filter-objects="parseEnums.enumToArray(executeVisibleActionsEnum)"
            :on-update-parameters="updateSelectedAction"
            :is-active-filter="isActiveFilter"
          />
          <searchable-dropdown
            :dropdown-properties="filters.DEVICE.value"
            :filter-objects="devicesData(deviceName)"
            :on-update-parameters="updateSelectedDevice"
            :on-update-v-model-input="updateDevicesQuery"
            :is-active-filter="isActiveFilter"
          />
          <searchable-dropdown
            :dropdown-properties="filters.USER.value"
            :filter-objects="users"
            :on-update-parameters="updateSelectedUser"
            :is-active-filter="isActiveFilter"
          />
          <b-datepicker
            v-model="filters.DATE_RANGE.value.selectedValue"
            :max-date="new Date()"
            class="dateRange is-outlined dropdown"
            :placeholder="filters.DATE_RANGE.value.label"
            :icon="filters.DATE_RANGE.value.icon.name"
            :mobile-native="false"
            :class="filters.DATE_RANGE.value.selectedValue.length > 0 ? 'selected' : ''"
            range
            @input="updateSelectedRange"
          />
          <searchable-dropdown
            :dropdown-properties="filters.STATE.value"
            :filter-objects="executionStates"
            :on-update-parameters="updateSelectedState"
            :is-active-filter="isActiveFilter"
          />
          <b-tooltip
            v-show="isActiveFilter"
            label="Reset filters"
            position="is-bottom"
            style="border-radius: 4px"
          >
            <b-button
              type="is-primary"
              @click="clearFilters"
            >
              <b-icon
                icon="filter-off"
              />
            </b-button>
          </b-tooltip>
        </div>
      </b-navbar-item>
    </portal>
    <card
      external-card="material-card-content"
    >
      <div
        v-for="(executionGroup) in executionGroupsResult"
        :key="executionGroup.id"
        slot="external-body"
      >
        <executions-group-card
          :execution-group="executionGroup"
          :displaying-execution-details="false"
          :filters-state="filters"
        />
      </div>
      <div slot="external-footer">
        <div
          v-show="executionGroupsResult.length > 0 && totalData > limitExecutionsGroup"
          class="pagination-container"
        >
          <b-pagination
            :total="totalData"
            :current.sync="currentPage"
            :per-page="limitExecutionsGroup"
            @change="changePagination"
          />
        </div>
        <div
          v-show="executionGroupsResult.length < 1"
          class="card material-card execution-group-card"
        >
          <div class="has-text-grey has-text-centered empty-content-message">
            <p>
              There are no executions to show
            </p>
          </div>
        </div>
      </div>
    </card>
    <b-loading
      v-model="isLoading"
      :is-full-page="true"
      :can-cancel="false"
    />
  </div>
</template>
<script>
import { debounce } from 'lodash';
import { mapGetters, mapActions } from 'vuex';
import { makeFindMixin } from 'feathers-vuex';
import moment from 'moment';
import Fuse from 'fuse.js';
import Enum from 'enum';
import card from '@/components/cross/Card.vue';
import executionsGroupCard from '@/components/executions/ExecutionsGroupCard.vue';
import SearchableDropdown from '@/components/cross/SearchableDropdown.vue';
import ExecutionInformation from '@/helpers/executionInformation';
import LoadingMixin from '@/mixins/loading';
import ErrorMixin from '@/mixins/error';
import CompanyMixin from '@/mixins/company';
import parseEnums from '@/helpers/parseEnums';
import vtulEnums from '../../../cross/index';
import executionsFilters from '../config/executionsFilters';

const { execution } = vtulEnums.enum;

const fuseOptions = {
  shouldSort: true,
  tokenize: true,
  matchAllTokens: true,
  maxPatternLength: 0,
  minMatchCharLength: 1,
  keys: ['name'],
};

export default {
  name: 'Executions',
  metaInfo: {
    title: 'Executions',
  },
  components: {
    card,
    'executions-group-card': executionsGroupCard,
    'searchable-dropdown': SearchableDropdown,
  },
  mixins: [LoadingMixin, ErrorMixin, CompanyMixin, makeFindMixin({ service: 'execution-groups', watch: true })],
  data() {
    return {
      parseEnums,
      limitExecutionsGroup: 15,
      skipExecutionsGroups: 0,
      currentPage: this.$route.params.page ? parseInt(this.$route.params.page, 10) : 1,
      executionStatusEnum: execution.executionStatus,
      executionActionsEnum: [],
      executeVisibleActionsEnum: [],
      filters: new Enum(JSON.parse(JSON.stringify(executionsFilters))),
      deviceName: '',
      isActiveFilter: false,
      filterState: null,
      startedOn: null,
      finishedOn: null,
      selectedState: null,
      lastExecutionGroupsFetch: null,
      executionsGroupsIds: null,
    };
  },
  computed: {
    ...mapGetters('user', { findUserInStore: 'find' }),
    ...mapGetters('execution-groups', { findExecutionGroupsInStore: 'find' }),
    ...mapGetters('memberships', { findMembershipsInStore: 'find' }),
    ...mapGetters('devices', { findDevicesInStore: 'find' }),

    user() {
      return this.$store.getters['auth/user'];
    },
    executionStates() {
      const stateList = [];
      this.executionStatusEnum.enums.forEach((state) => {
        const { key, value } = state;
        const stateValue = {
          value,
          name: `${key.substring(0, 1)}${key.substring(1).toLowerCase().replaceAll('_', ' ')}`,
        };
        stateList.push(stateValue);
      });
      return stateList;
    },
    supportAccount() {
      if (!this.currentCompany) {
        return null;
      }
      const user = this.findUserInStore({
        query: {
          isSupportAccount: true,
          $select: ['id'],
          $limit: 1,
        },
      });
      return user.data[0];
    },
    currentPaginationQuery() {
      // The order of the properties of this query matters, because that is the Id of the
      // current pagination, used by executionGroupsResult() method
      return {
        $limit: this.limitExecutionsGroup,
        $skip: this.skipExecutionsGroups,
      };
    },
    queryForSearchExecutionGroups() {
      // The order of the properties of this query matters, because that is the Id of the
      // current pagination, used by totalData() method.
      const query = {
        $sort: { createdAt: -1 },
        action: this.filters.ACTION.value.selectedValue,
        companyId: this.currentCompany.id,
      };

      if (this.filters.DEVICE.value.selectedValue && this.executionsGroupsIds) {
        query.id = { $in: this.executionsGroupsIds };
      }

      if (this.filters.DATE_RANGE.value.selectedValue.length > 0) {
        query.createdAt = {
          $gte: moment(this.filters.DATE_RANGE.value.selectedValue[0]).startOf('day').toISOString(),
          $lte: moment(this.filters.DATE_RANGE.value.selectedValue[1]).endOf('day').toISOString(),
        };
      }

      if (this.filters.STATE.value.selectedValue >= 0) {
        query[this.filters.STATE.value.columnId[this.filters
          .STATE.value.selectedValue]] = { $not: 0 };
      } else {
        query.$or = [
          {
            executionsWaitingToStart: {
              $not: 0,
            },
          }, {
            executionsRunning: {
              $not: 0,
            },
          }, {
            executionsFinished: {
              $not: 0,
            },
          },
        ];
      }

      if (this.supportAccount && this.supportAccount.id !== this.user.id) {
        query.userId = {
          $not: this.supportAccount.id,
        };
      }

      if (this.filters.USER.value.selectedValue) {
        query.userId = this.filters.USER.value.selectedValue;
      }

      return query;
    },
    executionGroupsParams() {
      return {
        query: {
          ...this.currentPaginationQuery,
          ...this.queryForSearchExecutionGroups,
        },
      };
    },
    executionGroupsResult() {
      const paginationId = JSON.stringify(this.currentPaginationQuery);
      if (!this.executionGroupsLatestQuery || !this.executionGroupsPaginationData.default
          || !this.executionGroupsPaginationData.default[this.executionGroupsLatestQuery.queryId]
          || !this.executionGroupsPaginationData
            .default[this.executionGroupsLatestQuery.queryId][paginationId]) {
        return [];
      }

      const { queryId } = this.executionGroupsLatestQuery;
      let executionGroupIds = this.executionGroupsPaginationData
        .default[queryId][paginationId].ids;

      if (this.currentPage === 1) {
        // This is a trick to display the new executions reactive
        if (this.lastExecutionGroupsFetch && this.executionGroups
          .some((x) => new Date(x.createdAt) > this.lastExecutionGroupsFetch)) {
          this.updateLastExecutionTime();
          this.updateExecutionGroupsIds();
        }
        if (!this.filters.DEVICE.value.selectedValue) {
          executionGroupIds = this.executionGroups.map((x) => x.id);
        }
        executionGroupIds = this.executionGroups.map((x) => x.id);
      }

      const executionsGroups = this.findExecutionGroupsInStore({
        query: {
          companyId: this.currentCompany.id,
          id: {
            $in: executionGroupIds,
          },
          $sort: { createdAt: -1 },
          $limit: this.limitExecutionsGroup,
        },
      });

      return executionsGroups.data;
    },
    users() {
      const memberships = this.findMembershipsInStore({
        query: {
          companyId: this.currentCompany.id,
        },
      });
      const query = {
        id: {
          $in: memberships.data.map((user) => user.userId),
        },
      };
      if (this.supportAccount && this.supportAccount.id !== this.user.id) {
        query.isSupportAccount = false;
      }
      const companyUsers = this.findUserInStore({
        query,
      });
      return companyUsers.data;
    },
    totalData() {
      if (!this.executionGroupsLatestQuery || !this.executionGroupsPaginationData.default
          || !this.executionGroupsPaginationData.default[this.executionGroupsLatestQuery.queryId]) {
        return 0;
      }

      const { queryId } = this.executionGroupsLatestQuery;
      return this.executionGroupsPaginationData.default[queryId].total;
    },
    isLoading() {
      return this.executionGroupsResult.length < 1 && this.isFindExecutionGroupsPending;
    },
    storedDevices() {
      let devices = null;
      if (this.currentCompany) {
        const sList = this.findDevicesInStore({
          query: {
            $sort: { name: 1 },
            companyId: this.currentCompany.id,
            $select: ['id', 'name', 'companyId', 'enabled'],
          },
        });
        if (sList && sList.data) devices = sList.data;
      }
      return devices;
    },
  },
  watch: {
    currentPage(newPage) {
      this.$router.replace(`/${this.$route.params.companyId}/executions/${newPage}`);
    },
    filters: {
      async handler() {
        if (this.filters.DEVICE.value.selectedValue) {
          await this.updateExecutionGroupsIds();
        }
      },
      deep: true,
    },
  },
  created() {
    this.fetchUsersByCompany();
    this.updateDevicesQuery();
    this.changePagination(this.currentPage);
    this.filterExecutionsByCapability();
    this.filterExecutionsByCapabilityAndVisibility();
  },
  methods: {
    ...mapActions('user', { findUsers: 'find' }),
    ...mapActions('memberships', { findMemberships: 'find' }),
    ...mapActions('devices', { findDevices: 'find' }),
    ...mapActions('executions-helper', { findExecutionGroupsIds: 'find' }),
    changePagination(page) {
      if (page) {
        this.currentPage = page;
      }
      this.skipExecutionsGroups = (this.currentPage - 1) * this.limitExecutionsGroup;
    },
    async fetchUsersByCompany() {
      const usersIdByMemberships = await this.findMemberships({
        query: {
          companyId: this.currentCompany.id,
        },
      });
      const usersId = usersIdByMemberships.data.map((membership) => membership.userId);
      await this.findUsers({
        query: {
          id: {
            $in: usersId,
          },
        },
      });
    },
    fetchDevices: debounce(async function fetchDevices(query) {
      await this.findDevices({ query });
      this.searching = false;
    }, 500),
    updateSelectedAction(action) {
      this.filters.ACTION.value.selectedValue = action.id;
      this.isActiveFilter = true;
    },
    updateSelectedUser(user) {
      this.filters.USER.value.selectedValue = user.id;
      this.isActiveFilter = true;
    },
    updateSelectedDevice(device) {
      this.lastExecutionGroupsFetch = Date.now();
      this.filters.DEVICE.value.selectedValue = device.id;
      this.isActiveFilter = true;
    },
    updateDevicesQuery(input) {
      this.deviceName = input;
      const query = {
        $sort: { name: 1 },
        $limit: 10,
        companyId: this.$route.params.companyId,
        enabled: true,
        $select: ['id', 'name', 'companyId', 'enabled'],
      };
      if (this.deviceName) {
        query.name = { $ilike: `%${this.deviceName.toLowerCase()}%` };
      }
      this.fetchDevices(query);
    },
    updateLastExecutionTime() {
      this.lastExecutionGroupsFetch = new Date();
    },
    updateSelectedState(state) {
      this.filters.STATE.value.selectedValue = state.value;
      this.isActiveFilter = true;
    },
    updateSelectedRange() {
      this.isActiveFilter = true;
    },
    clearFilters() {
      this.lastExecutionGroupsFetch = null;
      this.filters.ACTION.value.selectedValue = {
        $nin: ExecutionInformation.getHiddenActions(),
      };
      this.filters.USER.value.selectedValue = '';
      this.filters.STATE.value.selectedValue = -1;
      this.filters.DEVICE.value.selectedValue = '';
      this.deviceName = '';
      this.filters.DATE_RANGE.value.selectedValue = [];
      this.selectedState = null;
      this.isActiveFilter = false;
      this.executionsGroupsIds = null;
    },
    filterExecutionsByCapability() {
      this.executionActionsEnum = execution.executeAction.enums.filter(
        (executionObj) => this.validateIfHasCapability(executionObj.value.requiredCapability),
      );
    },
    filterExecutionsByCapabilityAndVisibility() {
      const visibleActions = ExecutionInformation.getVisibleActions();
      this.executeVisibleActionsEnum = visibleActions.filter(
        (executionObj) => this.validateIfHasCapability(executionObj.value.requiredCapability),
      );
    },
    devicesData(deviceNameToSearch) {
      const devices = this.storedDevices;
      const fuse = new Fuse(devices, fuseOptions);
      const response = deviceNameToSearch ? fuse.search(deviceNameToSearch) : devices;
      return response.slice(0, 10);
    },
    async updateExecutionGroupsIds() {
      const ids = await this.findExecutionGroupsIds({
        query: {
          type: execution.executionHelper.FILTER_EXECUTIONS.value,
          companyId: this.currentCompany.id,
          deviceId: this.filters.DEVICE.value.selectedValue,
          action: this.filters.ACTION.value.selectedValue,
          userId: this.filters.USER.value.selectedValue,
          dateRange: this.filters.DATE_RANGE.value.selectedValue,
          state: this.filters.STATE.value.selectedValue,
        },
      });
      this.executionsGroupsIds = ids.data;
    },
  },
};
</script>

<style>
  .execution-group-card {
    margin-top: 12px !important;
  }
  .empty-content-message {
    padding: 3%
  }
  .hideOfflineDevicesToggle {
    font-weight: normal;
  }
  .pagination-container{
    margin-top: 15px;
  }
  .loading-overlay.is-full-page {
    z-index: 9 !important;
  }
  .styleForTitle {
    margin-bottom: 24px
  }

  .dropdown + .dropdown {
    margin: 0px;
  }

  .selected input{
    background-color: #632d8e;
    color: white;
  }

  @media (max-width: 357px) {
  .dateRange .dropdown-menu{
    width: 100% !important;
  }
}

</style>
