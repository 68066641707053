<template>
  <b-dropdown
    v-model="selectedItem"
    :scrollable="true"
    max-height="247px"
    style="text-align: left;"
    aria-role="list"
    class="dropdown-scrollbar"
    :multiple="dropdownProperties.canSelectMultiple"
    :expanded="dropdownProperties.expanded"
    @input="onChangeState"
    @active-change="dropdownToggle"
  >
    <template
      #trigger="{ active }"
    >
      <b-button
        :icon-right="active ? 'menu-up' : 'menu-down'"
        class="button"
        :type="selectedItem && dropdownProperties.markAsActive !== false ? 'is-primary' : ''"
        expanded
        style="display:flex; justify-content: space-between;"
      >
        {{ selectedItem && !dropdownProperties.canSelectMultiple
          ? selectedItem[dropdownProperties.propertyToShow] : dropdownProperties.label }}
      </b-button>
    </template>
    <b-dropdown-item
      custom
      aria-role="listitem"
    >
      <b-input
        ref="searchBox"
        v-model="searchTerm"
        placeholder="Search..."
        expanded
        @input="onChangeInput"
      />
    </b-dropdown-item>
    <b-dropdown-item
      v-if="filters.length === 0"
      class="noselect"
      disabled
    >
      There are no matches with your search
    </b-dropdown-item>

    <b-dropdown-item
      v-for="filter in filters"
      v-show="!!filter[dropdownProperties.propertyToShow]"
      :key="filter.id"
      :value="filter"
      aria-role="listitem"
    >
      <div class="media center-status">
        <figure
          v-if="dropdownProperties.showImage"
          class="media-left"
        >
          <p class="image is-32x32 center-image">
            <b-image
              :src="`${$enrichWorker}?companyUrl=${filter.url}`"
              :alt="filter.url ?
                `${filter.name} logo` : 'Boardgent logo'"
              :src-fallback="require('@/assets/img/logo.svg')"
            />
          </p>
        </figure>
        <DeviceStatus
          v-if="dropdownProperties.showStatus"
          :status="deviceStatusColor[filter.color]"
          class="media-left"
        />
        <b-icon
          v-if="dropdownProperties.icon.showIcon"
          class="media-left"
          :icon="dropdownProperties.icon.name || filter.icon"
          size="is-small"
        />
        <div class="media-content noselect">
          {{ filter[dropdownProperties.propertyToShow] }} {{ filter.count || filter.count === 0
            ? `(${filter.count})` : '' }}
        </div>
      </div>
    </b-dropdown-item>
  </b-dropdown>
</template>

<script>
import Fuse from 'fuse.js';
import DeviceStatus from '@/components/device/DeviceStatus.vue';
import vtulEnums from '../../../../cross/index';

const fuseOptions = {
  shouldSort: true,
  tokenize: true,
  matchAllTokens: true,
  maxPatternLength: 0,
  minMatchCharLength: 1,
};
export default {
  name: 'SearchableDropdown',
  components: {
    DeviceStatus,
  },
  props: {
    parentSelectedItems: {
      type: Object,
      default: () => {},
    },
    dropdownProperties: {
      type: Object,
      required: true,
    },
    filterObjects: {
      type: Array,
      required: true,
    },
    onUpdateParameters: {
      type: Function,
      required: true,
    },
    onUpdateVModelInput: {
      type: Function,
      default: () => {},
    },
    isActiveFilter: {
      type: Boolean,
      default: false,
    },
    isExtended: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      searchTerm: '',
      filters: this.filterObjects,
      selectedItem: null,
      deviceStatusColor: vtulEnums.enum.device.deviceStatusColor,
      currentSelectedItems: null,
    };
  },
  computed: {
    fuse() {
      return new Fuse(this.filterObjects,
        Object.assign(fuseOptions, { keys: [this.dropdownProperties.propertyToShow] }));
    },
  },
  watch: {
    isActiveFilter: {
      async handler() {
        if (!this.isActiveFilter) {
          this.selectedItem = null;
          this.searchTerm = '';
        }
      },
      deep: true,
    },
    filterObjects: {
      async handler() {
        this.filters = JSON.parse(JSON.stringify(this.filterObjects));
        if (!this.dropdownProperties.customSort) {
          this.filters.sort(this.sortCaseInsensitive);
        }
        if (this.currentSelectedItems && this.currentSelectedItems.length > 0) {
          this.selectedItem = this.filters.filter(
            (x) => this.currentSelectedItems.includes(x[this.dropdownProperties.id])
                || this.currentSelectedItems.includes(x[this.dropdownProperties.filterKey]),
          );
        }
      },
      deep: true,
    },
    parentSelectedItems: {
      async handler(data) {
        this.currentSelectedItems = data[`${this.dropdownProperties.id}Filter`];
      },
      deep: true,
    },
  },
  created() {
    if (!this.dropdownProperties.customSort) {
      this.filters = this.filterObjects.sort(this.sortCaseInsensitive);
    }
  },
  methods: {
    onChangeState(parameter) {
      this.onUpdateParameters(parameter, this.dropdownProperties);
    },
    onChangeInput() {
      if (this.searchTerm) {
        this.filters = this.fuse.search(this.searchTerm);
      } else {
        this.filters = this.filterObjects;
      }
      if (!this.dropdownProperties.customSort) {
        this.filters.sort(this.sortCaseInsensitive);
      }
      this.onUpdateVModelInput(this.searchTerm);
    },
    dropdownToggle(dropdownActive) {
      if (dropdownActive) {
        this.$refs.searchBox.focus();
      }
    },
    sortCaseInsensitive(a, b) {
      const firstItem = a[this.dropdownProperties.propertyToShow].toLowerCase();
      const secondItem = b[this.dropdownProperties.propertyToShow].toLowerCase();
      if (firstItem > secondItem) {
        return 1;
      }
      if (firstItem < secondItem) {
        return -1;
      }
      return 0;
    },
  },
};
</script>
