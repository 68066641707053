<template>
  <status-indicator
    v-if="status === deviceStatusColor.BLUE"
    active
    :pulse="pulse"
  />
  <status-indicator
    v-else-if="status === deviceStatusColor.GREEN"
    positive
    :pulse="pulse"
  />
  <status-indicator
    v-else-if="status === deviceStatusColor.RED"
    negative
    :pulse="pulse"
  />
  <status-indicator
    v-else-if="status === deviceStatusColor.GRAY"
    inactive
    :pulse="pulse"
  />
  <status-indicator
    v-else-if="status === deviceStatusColor.YELLOW"
    intermediary
    :pulse="pulse"
  />
</template>

<script>
import vtulEnums from '../../../../cross/index';

export default {
  name: 'DeviceStatus',
  props: {
    status: {
      type: Object,
      required: true,
      default: () => {},
    },
    pulse: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      deviceStatusColor: vtulEnums.enum.device.deviceStatusColor,
    };
  },
};
</script>
