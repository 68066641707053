import ExecutionInformation from '@/helpers/executionInformation';
import vtulEnums from '../../../cross/index';

const Enum = require('enum');

const { executionStatus } = vtulEnums.enum.execution;

export default
new Enum({
  ACTION: {
    columnId: 'action',
    propertyToShow: 'name',
    label: 'Action',
    icon: {
      showIcon: false,
    },
    selectedValue: {
      $nin: ExecutionInformation.getHiddenActions(),
    },
    canSelectMultiple: false,
    customSort: false,
    expanded: false,
  },
  USER: {
    columnId: 'userId',
    propertyToShow: 'email',
    label: 'User',
    icon: {
      showIcon: false,
    },
    selectedValue: '',
    canSelectMultiple: false,
    customSort: false,
    expanded: false,
  },
  STATE: {
    columnId: {
      [executionStatus.SCHEDULED.value]: 'executionsWaitingToStart',
      [executionStatus.RUNNING.value]: 'executionsRunning',
      [executionStatus.FINISHED.value]: 'executionsFinished',
    },
    propertyToShow: 'name',
    label: 'State',
    icon: {
      showIcon: false,
    },
    selectedValue: -1,
    canSelectMultiple: false,
    customSort: false,
    expanded: false,
  },
  DEVICE: {
    columnId: 'deviceId',
    propertyToShow: 'name',
    label: 'Device',
    icon: {
      showIcon: false,
    },
    selectedValue: '',
    canSelectMultiple: false,
    customSort: true,
    expanded: false,
  },
  DATE_RANGE: {
    columnId: 'createdAt',
    propertyToShow: '',
    label: 'Date range',
    icon: {
      showIcon: true,
      name: 'calendar-today',
    },
    selectedValue: [],
    canSelectMultiple: false,
    expanded: false,
  },
});
