import vtulEnums from '../../../cross/index';

export default class ExecutionInformation {
  static getHiddenActions() {
    try {
      const hiddenActions = vtulEnums.enum.execution.executeAction.enums
        .filter((x) => x.value.hiddenActionResult);

      const hiddenActionsIDs = hiddenActions.map((action) => action.value.id);
      if (hiddenActionsIDs && hiddenActionsIDs.length > 0) return hiddenActionsIDs;
      return [];
    } catch (error) {
      return [];
    }
  }

  static getVisibleActions() {
    try {
      const visibleActions = vtulEnums.enum.execution.executeAction.enums
        .filter((x) => x.value.isVisibleForFilter);

      if (visibleActions && visibleActions.length > 0) return visibleActions;
      return [];
    } catch (error) {
      return [];
    }
  }
}
